import { useState, useEffect } from "react";

const useImageToByteArray = (imageData) => {
  const [byteArray, setByteArray] = useState(null);

  useEffect(() => {
    if (imageData) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const image = new Image();
      image.src = imageData;
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(blob);
          reader.onload = () => {
            const bytes = new Uint8Array(reader.result);
            const byteArray = Array.from(bytes);
            setByteArray(byteArray);
          };
        });
      };
    }
  }, [imageData]);

  return byteArray;
};

export default useImageToByteArray;
