import React, { useState } from "react";
import StoryFormImageComponent from "./StoryFormImageComponent";

import { CloseButton } from "../commons/buttons/ButtonsComponent";
import useHandleForm from "../../hooks/useHandleForm";

const StoryFormComponent = ({story = null,type,setFormStoryOpen,loadStories,}) => {
  
  const [photoWasChanged, setPhotoWasChanged] = useState(false);
  
  const { sending, handleFormSubmit, onInputChange , setPhoto, title, subtitle, briefDescription, description  } 
  = 
  useHandleForm(story,type,photoWasChanged,loadStories,setFormStoryOpen);

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormSubmit();
  };

  return (
    <div className="StoryFormComponent">
      <CloseButton isOpen={setFormStoryOpen}></CloseButton>
      <div className="StoryFormContainer">
        <StoryFormImageComponent
          story={story}
          imageChanged={setPhotoWasChanged}
          setPhoto={setPhoto}
        ></StoryFormImageComponent>
        <form className="storyForm" onSubmit={handleSubmit}>
          <div className="StoryFormTextContent">
            <label htmlFor="title">Título</label>
            <textarea
              rows="2"
              name="title"
              value={title}
              placeholder="Título"
              onChange={onInputChange}
              required
              minLength={1}
              maxLength={50}
            />
          </div>
          <div className="StoryFormTextContent">
            <label htmlFor="title">Subtítulo</label>
            <textarea
              rows="2"
              name="subtitle"
              placeholder="Subtítulo"
              value={subtitle}
              onChange={onInputChange}
              required
              minLength={1}
              maxLength={100}
            />
          </div>
          <div className="StoryFormTextContent">
            <label htmlFor="title">Reseña</label>
            <textarea
              rows="5"
              name="briefDescription"
              placeholder="Reseña"
              value={briefDescription}
              onChange={onInputChange}
              required
              minLength={1}
              maxLength={300}
            />
          </div>
          <div className="StoryFormTextContent">
            <label htmlFor="title">Descripción</label>
            <textarea
              rows="5"
              name="description"
              placeholder="Descripción"
              value={description}
              onChange={onInputChange}
              required
            />
          </div>
          <button
            type="submit"
            className="formComponentSubmitButton"
            disabled={sending}
          >
            {" "}
            {type == 'create' ? "Crear Noticia" : "Editar Noticia"}
            {" "}
          </button>
        </form>
      </div>
    </div>
  );
};

export default StoryFormComponent;
