import React from 'react'

const FooterContactInfo = ({type,info}) => {
  return (
    <div className='infoDataContainer'>
      <div className='infoDataTitle'>{type}</div>
      <div className='infoData'> {info}</div>
    </div>
  )
}

export default FooterContactInfo
