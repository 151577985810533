import React from "react";

const AdminSelector = ({ selection, setSelection }) => {
  
  
  const handleSelection = (sectionSelected) => {
    setSelection(sectionSelected);
  };

  return (
    <div className="sectionAdminSelector">
      <div
        className={`storyAdminSelected ${
          selection === "storyAdminSelected" ? "AdminSelectorSectionSelected" : ""
        }`}
        onClick={() => handleSelection("storyAdminSelected")}
      >
        <p>Administrar 
        Noticias</p>
      </div>
      <div
        className={`galleryAdminSelected ${
          selection === "galleryAdminSelected" ? "AdminSelectorSectionSelected" : ""
        }`}
        onClick={() => handleSelection("galleryAdminSelected")}
      >
       <p> Administrar 
        Galeria de Fotos</p>
      </div>
      <div
        className={`employeesAdminSelected ${
          selection === "employeesAdminSelected" ? "AdminSelectorSectionSelected" : ""
        }`}
        onClick={() => handleSelection("employeesAdminSelected")}
      >
        <p>Administrar 
        Plantilla de Empleados</p>
      </div>
    </div>
  );
};

export default AdminSelector;
