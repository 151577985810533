import "../../styles/admin.css";
import StoryFormComponent from "./StoryFormComponent";
import { deleteStory } from "../../fetchers/storiesFetcher";
import { useState } from "react";
import { EditButton, DeleteButton } from "../commons/buttons/ButtonsComponent";

const StoryCard = ({ story, loadStories, setFormStoryOpen, menuOpen }) => {
  const [showForm, setShowForm] = useState(false);
  const [showConfirmQuestionPopup, setShowConfirmQuestionPopup] =useState(false);
  const [storyToDeleteId, setStoryToDeleteId] = useState(null);


  const handleDeleteButtonClick = (id) => {
    setStoryToDeleteId(id);
    setShowConfirmQuestionPopup(true);
  };

  
  const handleDeleteStory = async (id) => {
    try {
      let res = await deleteStory(id);
      if (res.status === 204) {
        loadStories();
      }
    } catch (e) {}
  };

  return (
    <div className="storyCard">
      <div className="storyCardInputCheck">
        <input type="checkbox" name="select" id="" />
      </div>

      <div className="storyCardInfo">
        <div className="storyCardPhoto">
          {" "}
          <img src={story.photoPresignedUrl} alt={story.title} />{" "}
        </div>
        <div className="storyCardInfoText">
          <div className="storyCardTitle">{story.title}</div>
          <div className="storyCardSubtitle">{story.subtitle}</div>
          <div className="storyCardContent">
            <div className="storyCardbriefDescription">
              {story.briefDescription}
            </div>
            <div className="storyCardDescription">{story.description}</div>
          </div>
        </div>
      </div>

      <div className="storyCardButtons">
        <div type="button" onClick={() => setShowForm(true)}>
          <EditButton></EditButton>
        </div>
        <div
          onClick={() => {handleDeleteButtonClick(story.id);
          }}
        >
          <DeleteButton></DeleteButton>
        </div>
      </div>

      {showForm && (

        <>
        <div className="overlay"></div>
        <div className="popupContainer">
          <StoryFormComponent
            story={story}
            type="modify"
            setFormStoryOpen={setShowForm}
            loadStories={loadStories}
          />
        </div>
        </>
      )}

      {showConfirmQuestionPopup && (
        <>
         <div className="overlay"></div>
        <div className="confirmQuestionPopup">
          <p>¿Está seguro que desea elminar la noticia?</p>
          <div>
            <button onClick={()=>handleDeleteStory(storyToDeleteId)} className="ConfirmDeleteButton"> Si</button>
            <button onClick={()=>{setShowConfirmQuestionPopup(false); setStoryToDeleteId(null)}} className="ConfirmCancelButton">Cancelar</button>
          </div>
        </div>
        </>
      )}
    </div>
  );
};

export default StoryCard;
