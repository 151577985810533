import '../../styles/contact.css';
import Header from '../commons/header/Header';
import Footer from '../commons/footer/Footer';
import ContactViewInfo from './ContactViewInfo';
import ContactViewSocialMedia from './ContactViewSocialMedia';
import EmailIcon from '@mui/icons-material/Email';

function ContactView() {
  return (
    <div className="ContactView">
        <Header viewId="contact" clipPath/>
        <div className="ViewTitle">
          <h1> ACERCATE A NOSOTROS </h1>
        </div>
        <ContactViewInfo/>
        <div className="SocialMediaContainer">
          <p className="SocialMediaText"> TAMBIÉN PUEDES ENCONTRARNOS EN </p>
          <ContactViewSocialMedia/>
        </div>
        <div className="MailToContainer">
          <p className="MailToText"> O ENVIARNOS UN CORREO A </p>
          <div className="MailTo">
            <a href="mailto:coophospital@hotmail.com?Subject=Consulta%20general">
              <EmailIcon className='mailtoLogo'/>
              <p> coophospital@hotmail.com</p>
            </a>
          </div>
        </div>
        <Footer/>
    </div>
  );
}

export default ContactView;