import '../../styles/general.css';
import HomeVisionElem from './HomeVisionElem';
import heartImgSrc from '../../assets/img/heart.png';
import targetImgSrc from '../../assets/img/target.png';
import binocularsImgSrc from '../../assets/img/binoculars.png';

function HomeVision(props) {

  const coopValues = <div><p className="HomeVisionElemTextParagraph"><b>Altruismo:</b> Nuestra vocación, talento y compromiso para y por la comunidad.<br></br><br></br> <b>Cooperación:</b> Trabajamos en equipo con ambos hospitales para siempre estar a disposición de ellos en lo que nuestra institución pueda servirles. <br></br><br></br><b>Honestidad y humildad:</b> Una cualidad trascendental en todo nuestro personal, el fruto de nuestro trabajo es para la comunidad que habitamos y no por beneficio propio.</p></div>
  const coopVision = <div><p className="HomeVisionElemTextParagraph">Nuestra visión es ser reconocidos como una organización comprometida con los hospitales HIGA y HIEMI que presta su ayuda incondicional con responsabilidad social, con talento humano competente y comprometido con la mejora continua de nuestros procesos.<br></br><br></br>De esta manera poder brindarle a la comunidad marplatense una forma segura, sencilla y precisa para conocer y ayudar a ambos hospitales.</p></div>;
  const coopMision = <div><p className="HomeVisionElemTextParagraph">Nuestra misión es proporcionar toda la ayuda posible que este a nuestro alcance a los hospitales HIGA y HIEMI, además de proveer asistencia social a las personas carenciadas que se acerquen a nuestras instalaciones.<br></br><br></br>Esta misión, en su máximo alcance, solo sera posible con la ayuda de nuestra querida comunidad a la cual pertenecemos.</p></div>;

  return (
    <div className="HomeVision">
      <div className="HomeVisionCont">
        <HomeVisionElem imgSrc= {heartImgSrc} title="Valores" textContent={coopValues}/>
        <div className="verticalSeparator"> </div>
        <HomeVisionElem imgSrc= {binocularsImgSrc} title="Visión" textContent={coopVision}/>
        <div className="verticalSeparator"> </div>
        <HomeVisionElem imgSrc= {targetImgSrc} title="Misión" textContent={coopMision}/>
      </div>
    </div>
  );
}

export default HomeVision;
