import { useState,useEffect } from 'react'
import { getStories } from '../fetchers/storiesFetcher';

const useDataHook = () => {

    const [storyPreviewList, setStoryPreviewList] = useState([]);
    const [loading, setLoading] = useState(true);

    const getStoriesAsync = () => {
      try {
        getStories().then( (response) => {
          setStoryPreviewList(
            response.stories
            )
            ;
          setLoading(false);
        });
      } catch (error) {
        
      }
    }

    useEffect(() => {
        getStoriesAsync();
      }, []);

  return (
     {storyPreviewList, loading , getStoriesAsync }
  )
}

export default useDataHook
