import '../../styles/general.css';

function HomeVisionElem(props) {
  return (
    <div className="HomeVisionElem">
      <div className="HomeVisionElemCont">
        <div className="HomeVisionElemIcon"><img src={ props.imgSrc } alt="heart" /></div>
        <div className="HomeVisionElemTitle"> { props.title } </div>
        <div className="HomeVisionElemText"> { props.textContent } </div>
      </div>
    </div>
  );
}

export default HomeVisionElem;
