import CropperComponent from "./CropperComponent";
import useHandleStoryFormImageComponent from "../../hooks/useHandleStoryFormImageComponent";

const StoryFormImageComponent = ({ story = null, setPhoto, imageChanged }) => {

  const{photoModified, photo, showCroper , setShowCropper, handleSave, setPhotoModified}= useHandleStoryFormImageComponent(story, setPhoto, imageChanged);

  return (
    <div className="imageFormContainer">
      {photo && <img src={photo} alt="imagen en el formulario" />}

      <p>Seleccione una imagen que no exceda el limite establecido de 12 Mb</p>

      <button
        onClick={() => {
          setShowCropper(true);
        }}
      >
        Cambiar Imagen
      </button>
      {showCroper ? (
        <CropperComponent
          onSave={handleSave}
          photoWasModified={setPhotoModified}
          showCropper = {setShowCropper}
        ></CropperComponent>
      ) : null}
    </div>
  );
};

export default StoryFormImageComponent;
