import '../../../styles/footer.css';
import FooterLogo from './FooterLogo';
import FooterSocialMedia from './FooterSocialMedia';
import FooterMap from './FooterMap';
import FooterContactInfo from './FooterContactInfo';

function Footer() {
  return (
    <footer className="Footer">
      <div className="FooterCont">
      <div className='coopTitle'>
        <div className='coopTitleContainer'>
          <div >Cooperadora de Hospitales Higa Hiemi</div>
          <div ></div>
        </div>
      </div>
      <div className='mainContainer'>
        <FooterLogo/>
          <div className='textInfoContainer'>
            <FooterContactInfo type="Contacto" info="(+54 9 223) 4946152"></FooterContactInfo>
            <FooterContactInfo type="Informes" info="coophospital@hotmail.com"></FooterContactInfo>
            <FooterContactInfo type="Dirección" info="Castelli 2445 (Mar del Plata)"></FooterContactInfo>
          </div>
          <div className='infoRedes'>
            <div className='infoRedesText'>Encuentranos en nuestras redes sociales:</div>
            <FooterSocialMedia/>
          </div>
          <FooterMap/>
        </div>
    </div>
          
        <div className="copyright"> 2023 - Hospitales Higa Hiemi. Todos los derechos reservados.</div>
    </footer>
  );
}

export default Footer;
