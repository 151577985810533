import { getFetch, postFetch, putFetch, deleteFetch } from './apiFetcher';

const BASE_API_STORY_URL = 'stories';
const STORY_ID_URL = '/story/{id}';

const getStories = async (pageNumber) => {
    let pageNumberParam =  pageNumber ? "page=" + pageNumber : null;
    const stories = await getFetch(BASE_API_STORY_URL, pageNumberParam);
    return await stories.json();
}

const getStoryById = async (id) => {
    const story = await getFetch(BASE_API_STORY_URL + STORY_ID_URL.replace("{id}", id));
    return await story.json();
}

const createStory = async (story) => {
    const newStory = await postFetch(BASE_API_STORY_URL, story);
    return newStory;
}

const updateStory = async (id, story) => {
    const updatedStory = await putFetch(BASE_API_STORY_URL + STORY_ID_URL.replace("{id}", id), story);
    return await updatedStory;
}

const deleteStory = async (id) => {
    return await deleteFetch(BASE_API_STORY_URL + STORY_ID_URL.replace("{id}", id));
}

export { getStories, getStoryById, createStory, updateStory, deleteStory };