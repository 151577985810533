import '../../../styles/footer.css';
import logoBlackWhite from '../../../assets/img/logoHigaHiemi.png';

function FooterLogo() {
  return (
    <div className="FooterLogo">
        <img className="FooterLogoImg" src={logoBlackWhite} alt=""/>
    </div>
  );
}

export default FooterLogo;


