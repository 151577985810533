import { postFetch } from './apiFetcher';

const BASE_API_AUTH_URL = 'auth';
const LOGIN_URL = "/login"
const loginAuth = async (username, password) => {

    const authUser = {
        'username': username,
        'password': password
    }

    const newAuth = await postFetch(BASE_API_AUTH_URL + LOGIN_URL, authUser);

    if (!newAuth.ok) {
        sessionStorage.setItem("accessToken", null);
        sessionStorage.setItem("isLoggedIn", null);

        return null;
    }

    const data = await newAuth.json();

    sessionStorage.setItem("accessToken", data.accessToken);
    sessionStorage.setItem("isLoggedIn", "logged");

    return data;

};

const logOut = ()=>{
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("isLoggedIn");
}

export { loginAuth , logOut};

