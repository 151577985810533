import '../../styles/underConstruction.css';
import Header from '../commons/header/Header';
import Footer from '../commons/footer/Footer';
import underConstructionImage from '../../assets/img/sectionUnderConstruction.png';

function UnderConstructionView(){
    return (
        <div className="EmployeesView">
            <Header viewId="underConstruction" clipPath/>
            <div class="underConstruction flex center">
                <img src={underConstructionImage} class=" flex width-100"/>
            </div>
            <Footer/>
        </div>
      );
}

export default UnderConstructionView;