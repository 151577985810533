import { useState, useEffect, useRef } from "react";
import { getGalleryPhotos } from "../../fetchers/galleryPhotosFetcher"
import Carousel from 'react-gallery-carousel';
import Header from "../commons/header/Header";
import Footer from "../commons/footer/Footer";
import AppSpinner from '../commons/AppSpinner'
import GalleryPhotoView from "./GalleryPhotoView";
import '../../styles/gallery.css'
import 'react-gallery-carousel/dist/index.css';

function GalleryView() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const photosFetched =  useRef(false);

  
  const getGalleryPhotoAsync = (page) => {
    getGalleryPhotos(page).then((response) => {
      const srcImages = response.galleryPhotos.map(photo => (
        <GalleryPhotoView key={photo.id} 
        id={photo.id}
        photo={photo.photoPresignedUrl} 
        title={photo.title} 
        subtitle={photo.subtitle} 
        briefDescription={photo.briefDescription} />
      ));
      if (images.length === 0) {
        setImages(srcImages);
      } else {
        let newImages = [];
        newImages.push(...images, ...srcImages);
        setCurrentIndex(images.length - 3);
        setImages(newImages);
      }
      setPagination(response.pagination);
      setLoading(false);
    })
  }

  const onIndexChange = (indexes) => {
    if(images.length >= 0 && indexes.curIndexForDisplay >= (images.length - 2) 
      && pagination.currentPage < pagination.totalPages) {
      getGalleryPhotoAsync(pagination.currentPage + 1);
    }
  }

  useEffect(() => {
    if (photosFetched.current === false) {
      getGalleryPhotoAsync(1);
      photosFetched.current = true;
    }
  }, []);

  return (
    <div>
      <Header viewId="gallery" clipPath/>
      <div id="EmployeeViewTitle" className="ViewTitle">
        <h1> Galeria de fotos </h1>
      </div>
      <AppSpinner loading={loading} />
      <div className='carousel-container short'>
        <Carousel
          className="carousel"
          isLoop={false}
          hasIndexBoard={false}
          hasMediaButton={false}
          hasMediaButtonAtMax='bottomLeft'
          hasSizeButton={false}
          hasDotButtons='bottom'
          hasThumbnails={false}
          shouldSwipeOnMouse={false}
          shouldMinimizeOnSwipeDown={false}
          style={{ userSelect: 'text' }}
          index={currentIndex}
          onIndexChange={onIndexChange}>
          {images}
        </Carousel>
      </div>
      <Footer />
    </div>
  );
};

export default GalleryView;
