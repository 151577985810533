import { useEffect, useState } from "react";
import { getStoryById } from "../../fetchers/storiesFetcher";
import AppSpinner from "../commons/AppSpinner";
import { CloseButton } from "../commons/buttons/ButtonsComponent";
import "../../styles/general.css";
import "../../styles/stories-popup.css";

function StoriesPopUp({ id, isOpen }) {
  const [story, setStory] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchStory = async () => {
    try {
      const fetchedStory = await getStoryById(id);
      setStory(fetchedStory);
      setLoading(false);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchStory();
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <div className="StoriesPopUp">
      <div className="Overlay">
        {loading ? (
          <AppSpinner loading={loading} />
        ) : (
          <div className="PopUpContainer">
            <CloseButton isOpen={isOpen}></CloseButton>
            <div className="InfoContainer">
              <div className="text-and-photo">
                <h1>{story.title}</h1>
                <div className="StoryPopUpPhoto">
                  <img src={story.photoPresignedUrl} alt="Story photo" />
                </div>
                <h2>{story.subtitle}</h2>
                <p>{story.briefDescription}</p>
                {story.description.split("\n").map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default StoriesPopUp;