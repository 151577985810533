  import React from "react";
  import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
  import DeleteIcon from "@mui/icons-material/Delete";
  import EditIcon from "@mui/icons-material/Edit";
  import CloseIcon from "@mui/icons-material/Close";

  const DeleteButton = () => {
    return (
      <div>
        <button className="deleteButton">
          <DeleteIcon></DeleteIcon><span className="buttonText"> &nbsp; Eliminar</span>
        </button>
      </div>
    );
  };

  const EditButton = () => {
    return (
      <div>
        <button className="editButton">
          <EditIcon></EditIcon><span className="buttonText"> &nbsp; Editar</span>
        </button>
      </div>
    );
  };
  const CreateButton = () => {
    return (
      <div>
        <button className="createButton">
          <LibraryAddIcon></LibraryAddIcon>
        </button>
      </div>
    );
  };


  // This button receives a 'isOpen' function that, when clicked, sets it to false 
  const CloseButton = ({ isOpen }) => {
    return (
      <div className="formCloseButton">
        <button onClick={()=>isOpen(false)}>
          <CloseIcon></CloseIcon>
        </button>
      </div>
    );
  };

  export { CreateButton, EditButton, DeleteButton, CloseButton };
