import '../../../styles/footer.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function FooterMap() {
  return (
    <div className="FooterMap">
        <div className="FooterMapFrameContainer">
            <iframe id="googleMap" className='footerIframe' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3143.6041241654843!2d-57.55531938409075!3d-38.009693353054075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9584dc1fe0ea74c5%3A0x623bb590ac719a1e!2sCooperadora%20de%20los%20Hospitales%20de%20Mar%20del%20Plata%20(HIGA%20-%20HIEMI)!5e0!3m2!1ses-419!2sar!4v1680053209767!5m2!1ses-419!2sar" 
                title="map" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className="FooterMapLogo">
          <a href="https://www.google.com/maps/place/Cooperadora+de+los+Hospitales+de+Mar+del+Plata+(HIGA+-+HIEMI)/@-38.0096933,-57.5557056,17z/data=!3m1!4b1!4m6!3m5!1s0x9584dc1fe0ea74c5:0x623bb590ac719a1e!8m2!3d-38.0096976!4d-57.5531307!16s%2Fg%2F11bbrm4qrz?entry=ttu" 
              target="_blank" rel="noreferrer">
              <div className="locationLogo bg-white rounded-circle darkgreentxt footerIcons">
                  <LocationOnIcon></LocationOnIcon>
              </div>
          </a>
        </div>
    </div>

  );
}

export default FooterMap;




