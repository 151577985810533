import React, { useState, useCallback, useRef, useEffect } from "react";

const useHandleCropper = (onSave,photoWasModified) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imageSrc, setImageSrc] = useState(null); 
  const [croppedImage, setCroppedImage] = useState(null);
  const [shouldRenderControls, setShouldRenderControls] = useState(false);

  
  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const image = new Image();
      image.src = imageSrc;


      image.onload = () => {
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );
        const croppedImageUrl = canvas.toDataURL(); // Obtiene la URL de la imagen recortada
        setCroppedImage(croppedImageUrl); // Actualiza el estado con la imagen recortada
      };
    },
    [imageSrc]
  );

  // Manejar el cambio en el input de archivo
  const handleFileChange = (e) => {
    const file = e.target.files[0]; 
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageSrc(imageUrl); 
      setShouldRenderControls(true); 
    }
  };

  //Guardar la imagen y sacarla del componente hacia afuera
  const handleSaveClick = () => {
    onSave(croppedImage); 
    photoWasModified(true);
  };

  return {
    handleSaveClick, handleFileChange, onCropComplete, crop, zoom, imageSrc, croppedImage, shouldRenderControls, setZoom, setCrop
  }
}

export default useHandleCropper
