import React, { useRef, useEffect } from "react";
import Cropper from "react-easy-crop";
import { CloseButton } from "../commons/buttons/ButtonsComponent";
import useHandleCropper from "../../hooks/useHandleCropper";

const CropperComponent = ({ onSave, photoWasModified, showCropper }) => {
  const inputRef = useRef(null); // Referencia al elemento input de archivo

  const {
    handleSaveClick,
    handleFileChange,
    onCropComplete,
    crop,
    zoom,
    imageSrc,
    croppedImage,
    shouldRenderControls,
    setZoom,
    setCrop,
  } = useHandleCropper(onSave, photoWasModified);

  // sin este useRef y shouldLog, el click se ejecuta 2 veces por el stricMode de react.
  // lo dejo para que sea mas facil trabajar.
  const shouldLog = useRef(true);

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false; //
      inputRef.current.click();
    }
  }, []);

  return (
    <>
      <div className="overlay">
        <div className="cropper-wrapper">
          <CloseButton isOpen={showCropper}></CloseButton>
          {imageSrc && (
            <div className="crop-container">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={4 / 3} // Cuadro recortado
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          )}
          {shouldRenderControls && (
            <div className="controls">
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value);
                }}
                className="zoom-range"
              />
              {imageSrc && (
                <button onClick={handleSaveClick} className="save-button">
                  Guardar
                </button>
              )}
            </div>
          )}
          <div
            className="inputFileImageCropperContainer"
            style={{ display: shouldLog.current ? "none" : "flex" }}
          >
            <input
              type="file"
              id="inputFileCropper"
              accept="image/*"
              onChange={handleFileChange}
              ref={inputRef} // Asigna la referencia al elemento input para evitar que se ejecute 2 veces al principio.
              className="inputFileImageCropper"
              style={{ display: "none" }}
            />
            <label htmlFor="inputFileCropper" className="inputFileLabel">
              Elige una foto
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default CropperComponent;
