import '../../../styles/footer.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
function FooterSocialMedia() {
  return (
    <div className="FooterSocialMedia">
        <a className="footerPhoneContactContainer footerIcons" href="/contact" target="_blank" rel="noreferrer">
            <div className="phoneContactLogo bg-white rounded-circle darkgreentxt ">
                <LocalPhoneIcon></LocalPhoneIcon>
            </div>
        </a>
        
        <a className="footerFacebookLogoContainer footerIcons" href="https://www.facebook.com/profile.php?id=100005276839103" target="_blank" rel="noreferrer">
            <div className="facebookLogo bg-white rounded-circle darkgreentxt ">
                <FacebookIcon></FacebookIcon>
            </div>
        </a>
        <a className="footerInstagramLogoContainer footerIcons" href="https://www.instagram.com/cooperadorahospitales/" target="_blank" rel="noreferrer">
            <div className="phoneLogo bg-white rounded-circle darkgreentxt ">
                <InstagramIcon></InstagramIcon>
            </div>
        </a>
        
    </div>
  );
}

export default FooterSocialMedia;




