import '../../styles/general.css';
import StoryPreview from '../stories/StoryPreview';
import { useState, useEffect, useRef } from "react";
import { getStories } from '../../fetchers/storiesFetcher';
import AppSpinner from '../commons/AppSpinner';

function HomeStories(props) {

  const [storyPreviewList, setStoryPreviewList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getStoriesAsync = () => {
    try {
      getStories().then( (response) => {
        setStoryPreviewList(response.stories.slice(0,2));
        setLoading(false);
      });
    } catch (error) {

    }
  }

  const storiesFetched =  useRef(false);

  useEffect(() => {
    if (storiesFetched.current === false) {
      getStoriesAsync();
      storiesFetched.current = true;
    }
  }, []);

  return (
    <div className="MainCont">
      <div className="sectionTitle">NOTICIAS RECIENTES</div>
      <AppSpinner loading={loading}/>
      {storyPreviewList.map((story) =>
        <StoryPreview 
          key={story.id} 
          id={story.id}
          title={story.title} 
          subtitle={story.subtitle} 
          briefDescription={story.briefDescription} 
          photo={story.photoPresignedUrl} 
        />
      )}
    </div>
  );
}

export default HomeStories;
