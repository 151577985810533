export function convertBase64ToImage(base64String, photoName) {
  return <div> <img className="modifyImg"src={`data:image/jpeg;base64,${base64String}`} alt={photoName}/></div>
}

export function convertPublicSignedURLToImage(publicSignedURL, photoName) {
  return <div> <img className="modifyImg"src={`${publicSignedURL}`} alt={photoName}/></div>
}

export function base64ToIntArray(base64String) {
  const binaryString = atob(base64String);
  const intArray = [];
  for (let i = 0; i < binaryString.length; i++) {
    intArray.push(binaryString.charCodeAt(i));
  }
  return intArray;
}

export async function convertPublicURLToByteArray(publicURL) {
  try {
    const response = await fetch(publicURL);
    const buffer = await response.arrayBuffer();
    const byteArray = new Uint8Array(buffer);
    return byteArray;
  } catch (error) {
    console.error("Error fetching or converting URL to byteArray:", error);
    return null;
  }
}