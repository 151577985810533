import '../../../styles/general.css';

function HeaderMenu(props) {

  const getClassName = (menuItemId) => {
    let baseClassName = "HeaderMenuElement";
    if (menuItemId === props.viewId) {
      return baseClassName + " selected";
    }
    return baseClassName;
  }

  return (
    <div className="HeaderMenu" >
      <div className="HeaderMenuCont" >
        <div className={getClassName("home")}><a href="/">INICIO</a></div>
        <div className={getClassName("stories")}><a href="/stories">NOTICIAS</a></div>
        <div className={getClassName("gallery")}><a href="/gallery">GALERIA</a></div>
        <div className={getClassName("employees")}><a href="/employees">C. DIRECTIVA</a></div>
        <div className={getClassName("contact")}><a href="/contact">CONTACTO</a></div>
      </div>
    </div>
  );
}

export default HeaderMenu;
