import { Button } from '@mui/material';
import '../../styles/general.css';
import '../../styles/stories.css';
import { convertPublicSignedURLToImage } from '../../utils/ImageHelper';
import StoriesPopUp from './StoriesPopUp';
import { useEffect, useState } from 'react';

function StoryPreview(props) {
  const description = props.briefDescription;
  const showDots = description.length > 300;

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleClick = () => {
    setIsPopupVisible(true);
  }

  return (
    <div className="StoryPreview">
      <div className="StoryPreviewCont">
        <div className="StoryPreviewContLeft">
          <div> { convertPublicSignedURLToImage(props.photo, "name") } </div>
        </div>
        <div className="StoryPreviewContRight"> 
          <div className="StoryPreviewTitle"> { props.title } </div>
          <div className="StoryPreviewContRightCont">
            <div className="StoryPreviewSubtitle"> { props.subtitle } </div>
            <div className="StoryPreviewDescription">
              {showDots ? (<>{description.substring(0, 300)} ...</>) : (description)}
            </div>
            <div className="StoryPreviewContRightContButton">
              <Button className='ReadMoreButton' variant="contained" color="success" onClick={handleClick} > Ver Mas ... </Button>
            </div>
          </div>
        </div>
      </div>
      {isPopupVisible && <StoriesPopUp id={props.id} isOpen={()=>setIsPopupVisible()}/>}
    </div>
  );
}

export default StoryPreview;