import "../styles/general.css";
import HomeView from "./home/HomeView";
import StoriesView from "./stories/StoriesView";
import EmployeesView from "./employees/EmployeesView";
import GalleryView from "./gallery/GalleryView";
import UnderConstructionView from "./underConstruction/underConstructionView";
import ContactView from "./contact/ContactView";
import LoginView from "./login/LoginView";
import { AuthProvider } from "../utils/context/AuthProvider";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminView from "../components/admin/AdminView";

function AppCont() {
  return (
    <div className="AppCont">
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="/stories" element={<StoriesView />} />
            {/* <Route path="/employees" element={<EmployeesView />} /> */}
            <Route path="/employees" element={<UnderConstructionView />} />
            <Route path="/contact" element={<ContactView />} />
            {/* <Route path="/gallery" element={<GalleryView />} /> */}
            <Route path="/gallery" element={<UnderConstructionView />} />
            <Route path="/login" element={<LoginView />} />
            <Route path="/admin" element={<AdminView />} />
            <Route path="/*" element={<Navigate to="/"></Navigate>}/>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default AppCont;
