import React, { useEffect, useState } from 'react';
import AuthContext from './AuthContext';

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({
        accessToken: sessionStorage.getItem('accessToken') || null,
        isLoggedIn: sessionStorage.getItem('isLoggedIn') === 'logged',
    });

    useEffect(() => {
        setUser({
            accessToken: sessionStorage.getItem('accessToken') || null,
            isLoggedIn: sessionStorage.getItem('isLoggedIn') === 'logged',
        });
    }, []);

    return (
        <AuthContext.Provider value={{ user }}>
            {children}
        </AuthContext.Provider>
    );
};

export {AuthProvider};
