import '../../../styles/general.css'
import { Link } from 'react-router-dom'; 

function HeaderLogo() {
  return (
    <div className="HeaderLogo">
      <Link to="/">
        <img src={process.env.PUBLIC_URL + '/logocoop.png'} alt="Logo" className="HeaderLogoImg" />
      </Link>
    </div>
  );
}

export default HeaderLogo;
