import React from 'react'
import underConstructionImage from '../../assets/img/sectionUnderConstruction.png';
import '../../styles/underConstruction.css';


const GalleryAdminComponent = () => {
  return (
    <div class="underConstruction flex center">
      <img src={underConstructionImage} class="underConstruction m-top-20 flex width-100"/>
    </div>
  )
}

export default GalleryAdminComponent
