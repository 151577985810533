import '../../styles/general.css';
import '../../styles/home.css';
import Header from '../commons/header/Header';
import Footer from '../commons/footer/Footer';
import HomeVision from './HomeVision';
import HomeStories from './HomeStories';
import HomeImg from './HomeImg';

function HomeView() {
  return (
    <div id="HomeView" className="view">
        <Header viewId="home"/>
        <HomeImg/>
        <HomeVision/>
        <div className="horizontalSeparator"></div>
        <HomeStories/>
        <Footer/>
    </div>
  );
}

export default HomeView;
