import { useEffect, useState } from 'react'
import useImageToByteArray from "./useImageToByteArray";

const useHandleStoryFormImageComponent = (story, setPhoto, imageChanged) => {

    const [photoModified, setPhotoModified] = useState(false);
    const [photo, setPhotoInThisComponent] = useState("");
    const [showCroper, setShowCropper] = useState(false);

    useEffect(() => {
        if (story) setPhotoInThisComponent(story.photoPresignedUrl);
    }, []);

    const handleSave = (croppedImage) => {
        setPhotoInThisComponent(croppedImage);
        setShowCropper(false);
    };

    const byteArray = useImageToByteArray(photoModified ? photo : null);

    useEffect(() => {
        setPhoto(byteArray);
        imageChanged(true);
    }, [byteArray]);

    return {
        photoModified, photo, showCroper , setShowCropper, handleSave, setPhotoModified
    }
    
}

export default useHandleStoryFormImageComponent
