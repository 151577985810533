import '../../styles/general.css';
import Header from '../commons/header/Header';
import Footer from '../commons/footer/Footer';
import StoryPreviewContainer from './StoryPreviewContainer';

function StoriesView() {
  return (
    <div className="StoriesView view">
        <Header viewId="stories" clipPath/>
    
        <StoryPreviewContainer/>
        <Footer/>
    </div>
  );
}

export default StoriesView;
