import { useEffect } from 'react';
import '../../styles/general.css';


function HomeImg(props) {


  
  return (
    <div className="HomeImg"  >
      <div className="HomeImgCont">
        <img src={process.env.PUBLIC_URL + '/hospital.png'} alt="Logo" className='HomeImgContLogo'/>
      </div>
    </div>
  );
}

export default HomeImg;