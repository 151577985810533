import '../../styles/contact.css';
import DirectionsIcon from '@mui/icons-material/Directions';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

function ContactViewInfo() {
  return (
    <div className="ContactViewInfo">
        <div className="ContactInfo">
          <div className='ContactInfoContent'>
            <div className='ContactInfoContentSup'>
              <span><DirectionsIcon /></span> <p> DIRECCIÓN </p>
            </div>
            <div className='ContactInfoContentInf'>
              <p>Castelli 2445 (Mar del Plata)</p>
            </div>
          </div>
          <div className='ContactInfoContent'>
            <div className='ContactInfoContentSup'>
              <span><LocalPhoneIcon /></span> <p> TELÉFONO </p>
            </div>
            <div className='ContactInfoContentInf'>
              <p>(+54 9 223) 4946152</p>
            </div>
          </div>
          <div className='ContactInfoContent'>
            <div className='ContactInfoContentSup'>
              <span><EmojiPeopleIcon /></span> <p> HORARIOS DE ATENCIÓN </p>
            </div>
            <div className='ContactInfoContentInf'>
              <p>De 8 hs. a 15 hs.</p>
            </div>
          </div>
        </div>
        <div className="ContactMapFrame">
            <iframe id="googleMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3143.6041241654843!2d-57.55531938409075!3d-38.009693353054075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9584dc1fe0ea74c5%3A0x623bb590ac719a1e!2sCooperadora%20de%20los%20Hospitales%20de%20Mar%20del%20Plata%20(HIGA%20-%20HIEMI)!5e0!3m2!1ses-419!2sar!4v1680053209767!5m2!1ses-419!2sar" 
                title="map" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
  );
}

export default ContactViewInfo;