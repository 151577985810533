const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
  let [resource, config] = args;
  const token = sessionStorage.getItem('accessToken');
  
  // Si hay un token y la configuración existe, agrega la cabecera de autorización
  if (token && config) {
    if (!config.headers) {
      config.headers = {}; // Crea un objeto de cabeceras si no existe
    }
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  const response = await originalFetch(resource, config);
  return response;

  // revisar 401. desloguear --> volver a login, borrar sessionStorage.
  
};
