import React, { useEffect, useState } from "react";
import StoryCard from "./StoryCard";
import useDataHook from "../../hooks/useDataHook";
import StoryFormComponent from "./StoryFormComponent";
import AddIcon from '@mui/icons-material/Add';

const StoryAdminComponent = () => {
  const { storyPreviewList, loading, getStoriesAsync } = useDataHook();
  const [formStoryOpen, setFormStoryOpen] = useState(false);
  const displayedStories = storyPreviewList.slice(0, 2);

  const loadStories = () => {
    getStoriesAsync();
  };

  useEffect(() => {
  }, [storyPreviewList]);

  return (
    <div className="StoryAdminComponent">
      <div className="addNew" >
        <button onClick={() => setFormStoryOpen(true)} > Agregar Noticia &nbsp; <AddIcon></AddIcon></button>
      </div>

      {formStoryOpen && (
        <div className="popupContainer">
          <StoryFormComponent
            type="create"
            setFormStoryOpen={setFormStoryOpen}
            loadStories={loadStories}
          />
        </div>
      )}

      {loading ? (
        <p>Loading...</p>
      ) 
      : 
      (
        displayedStories.map((story) => (
          <StoryCard
            story={story}
            key={story.id}
            loadStories={loadStories}
            setFormStoryOpen={setFormStoryOpen}
          />
        ))
      )}
    </div>
  );
};

export default StoryAdminComponent;
