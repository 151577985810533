import React, { useEffect, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import HeaderMenu from './HeaderMenu';
import useWindowSize from '../../../hooks/WindowSizeHook';

function HamburgerHeader(props) {
  const [windowHeight, windowWidth] = useWindowSize();
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const [customWidth , setCustomWidth] = useState(200)

  const logMenuState = (state) => {
    setMenuOpen(state.isOpen);
    if (props.onStateChange) {
      props.onStateChange(state.isOpen); 
    }
  };

  useEffect(() => {

    if (windowWidth > 992) {
      setMenuOpen(false);
    } else if (windowWidth < 480) {
      setCustomWidth(200);
    } else if (windowWidth < 768) {
      setCustomWidth(350);
    } else {
      setCustomWidth(450);
    }
  }, [windowWidth]);

  return (
    <Menu
      right
      width={customWidth}
      isOpen={isMenuOpen}
      onStateChange={logMenuState}
    >
      <HeaderMenu viewId={props.viewId} />
    </Menu>
  );
}

export default HamburgerHeader;
