import "../../styles/login.css";

import Header from "../commons/header/Header";
import Footer from "../commons/footer/Footer";
import LoginForm from "./LoginForm";

const LoginView = () => {

  return (
    <div className="LoginView">
      <Header viewId="login" />
      <div className="generalContainer">
        <div className="loginDualContainer">
          <div className="loginInfo"></div>
          <LoginForm></LoginForm>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LoginView;
