import '../../styles/contact.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

function ContactViewSocialMedia() {
  return (
    <div className="ContactViewSocialMedia">
        <a className="footerFacebookLogoContainer" href="https://www.facebook.com/profile.php?id=100005276839103" target="_blank" rel="noreferrer">
            <div className="facebookLogo bg-white rounded-circle darkgreentxt">
                <FacebookIcon style={{ fontSize: '35px' }}/>
            </div>
        </a>
        <a className="footerInstagramLogoContainer" href="https://www.instagram.com/cooperadorahospitales/" target="_blank" rel="noreferrer">
            <div className="instagramLogo bg-white rounded-circle darkgreentxt">
                < InstagramIcon style={{ fontSize: '35px' }}/>
            </div>
        </a>
    </div>
  );
}

export default ContactViewSocialMedia;