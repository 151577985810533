import '../../styles/general.css';
import StoryPreview from './StoryPreview';
import { useState, useEffect, useRef } from "react";
import { getStories } from '../../fetchers/storiesFetcher';
import AppSpinner from '../commons/AppSpinner';
import Pagination from '../commons/Pagination';

function StoryPreviewContainer(props) {

  const [storyPreviewList, setStoryPreviewList] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [loading, setLoading] = useState(true);
  const storiesFetched =  useRef(false);

  const getStoriesAsync = (page) => {
    try {
      getStories(page).then( (response) => {
        setStoryPreviewList(response.stories);
        setPagination(response.pagination);
        setLoading(false);
      });
    } catch (error) {
    }
  }

  useEffect(() => {
    if (storiesFetched.current === false) {
      getStoriesAsync(1);
      storiesFetched.current = true;
    }
  }, []);

  const handlePaginationPrevious = () => {
    getStoriesAsync(pagination.currentPage - 1);
  }

  const handlePaginationNext = () => {
    getStoriesAsync(pagination.currentPage + 1);
  }

  return (
    <div className="MainCont">
      <AppSpinner loading={loading}/>
      {storyPreviewList.map((story) =>
        <StoryPreview 
          key={story.id}
          id={story.id}
          title={story.title} 
          subtitle={story.subtitle} 
          briefDescription={story.briefDescription} 
          photo={story.photoPresignedUrl} 
        />
      )}
      <Pagination pagination={pagination} handlePaginationPrevious={handlePaginationPrevious} handlePaginationNext={handlePaginationNext}/>
    </div>
  );
}

export default StoryPreviewContainer;
