import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../utils/context/AuthContext";
import Header from "../commons/header/Header";
import AdminSelector from "./AdminSelector";
import GalleryAdminComponent from "./GalleryAdminComponent";
import EmployeesAdminComponent from "./EmployeesAdminComponent";
import StoryAdminComponent from "./StoryAdminComponent";
import Footer from "../commons/footer/Footer";
import { logOut } from "../../fetchers/authFetcher";
import { Button } from "bootstrap";
const AdminView = () => {
  const { user } = useContext(AuthContext);

  const [sectionSelected, setSectionSelected] = useState("");
  const [textTitle, setTextTitle] = useState("");
  const [showConfirmQuestionPopup, setShowConfirmQuestionPopup] =
    useState(false);

  const handleSectionSelected = (selection) => {
    setSectionSelected(selection);
    switch (selection) {
      case "storyAdminSelected":
        setTextTitle("Administrar noticias");
        break;
      case "galleryAdminSelected":
        setTextTitle("Administrar galería de fotos");
        break;
      case "employeesAdminSelected":
        setTextTitle("Administrar plantilla de empleados");
        break;
      default:
        setTextTitle("Administración web Cooperadora HIGA - HIEMI");
        break;
    }
  };

  const handleLogOut = () => {
    window.location.href = "/";
    logOut();
  };

  useEffect(() => {
    handleSectionSelected("backSelector");
  }, []);
  useEffect(() => {}, [sectionSelected]);

  if (!user.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="AdminView">
      <Header clipPath></Header >
      <h1 className="sectionTitle">{textTitle}</h1>
      <div className="divider"></div>

      <section className="adminContainer">
        <div>
          {(() => {
            switch (sectionSelected) {
              case "storyAdminSelected":
                return <StoryAdminComponent />;
              case "galleryAdminSelected":
                return <GalleryAdminComponent className="selected" />;
              case "employeesAdminSelected":
                return <EmployeesAdminComponent className="selected" />;
              default:
                return (
                  <AdminSelector
                    selection={sectionSelected}
                    setSelection={handleSectionSelected}
                  />
                );
            }
          })()}
        </div>

        {sectionSelected !== "backSelector" ? (
          <div className="buttonReturnContainer">
            <button
              className="adminPrimaryButton "
              onClick={() => handleSectionSelected("backSelector")}
            >
              Regresar
            </button>
          </div>
        ) : (
          <div className="buttonReturnContainer">
            <button
              className="adminPrimaryButton "
              onClick={() => setShowConfirmQuestionPopup(true)}
            >
             Salir
            </button>
          </div>
        )}
        {showConfirmQuestionPopup && (
          <>
            <div className="overlay"></div>
            <div className="confirmQuestionPopup">
              <p>¿Está seguro de que desea cerrar sesión?</p>
              <div>
                <button onClick={() => handleLogOut()}> Si</button>
                <button
                  onClick={() => {
                    setShowConfirmQuestionPopup(false);
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </>
        )}
      </section>

      <Footer></Footer>
    </div>
  );
};

export default AdminView;
