import LoginLogo from "./LoginLogo";
import { ErrorMessage } from "../../utils/Messages";
import { loginAuth } from "../../fetchers/authFetcher";
import React, { useState } from "react";



const LoginForm = () => {
    
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authFailed, setAuthFailed] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = await loginAuth(username, password);
      if (token) {
        window.location.href = "/admin";
      } else {
        setAuthFailed(true);
      }
    } catch (e) {
    }
  };


  return (
    <div className="loginFormContainer">
      <LoginLogo></LoginLogo>
      <h5>Iniciar sesión</h5>
      <form action="" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Usuario"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        {authFailed && <ErrorMessage message="Usuario o contraseña incorrecto"/>}
        <button type="submit">Continuar</button>
      </form>
    </div>
  );
};

export default LoginForm;
