import '../../styles/general.css';

function Pagination(props) {

  return (
    <div className="Pagination">
      <div className="PaginationElem">
       {props.pagination.currentPage > 1 && 
        <a href="#" onClick={props.handlePaginationPrevious}>Anterior</a>
       }
      </div>
      <div className="PaginationElem"> Página {props.pagination.currentPage} </div>
      <div className="PaginationElem">
      {props.pagination.currentPage < props.pagination.totalPages && 
        <a href="#" onClick={props.handlePaginationNext}>Siguiente</a>
       }
      </div>
    </div>
  );
}


export default Pagination;
