const baseUrlLocalhost = 'https://coophiemihigamdp.com.ar:8443/';
const baseUrl = baseUrlLocalhost;

const getFetch = async (serviceUrl, queryParams) => {
    let endpoint = baseUrl + serviceUrl;
    if (queryParams != null) {
        endpoint = endpoint + '?' + queryParams;
    }
    return await fetch(endpoint);
}

const postFetch = async (serviceUrl, requestBody) => {
    let endpoint = baseUrl + serviceUrl;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    return await fetch(endpoint, requestOptions);
}

const putFetch = async (serviceUrl, requestBody) => {
    let endpoint = baseUrl + serviceUrl;
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    };
    return await fetch(endpoint, requestOptions);
}

const deleteFetch = async (serviceUrl) => {
    let endpoint = baseUrl + serviceUrl;
    const requestOptions = {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json",
        }
    };
    return await fetch(endpoint, requestOptions);
}

export { getFetch, postFetch, putFetch, deleteFetch};