import '../../styles/general.css'
import ClipLoader from "react-spinners/ClipLoader";

function AppSpinner(props) {
  return (
    <div className="AppSpinner">
      <ClipLoader
        color="orange"
        loading={props.loading}
        size={75}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default AppSpinner;
