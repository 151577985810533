import './App.css';
import AppCont from './components/AppCont';
import Interceptor from './fetchers/Interceptor';

function App() {
  return (
    <div className="App">
      <AppCont /> 
    </div>
  );
}

export default App;
