import '../../styles/general.css'

function LoginLogo() {
  return (
    <div className="LoginLogo">
      <img src={process.env.PUBLIC_URL + '/logocoop.png'} alt="Logo" className="LoginLogoImg"/>
    </div>
  );
}

export default LoginLogo;