import { useEffect, useState } from "react";
import { updateStory } from "../fetchers/storiesFetcher";
import { createStory } from "../fetchers/storiesFetcher";
import { getDate } from "../utils/simpleFunctions";
const useHandleForm = (story, type, photoWasChanged, loadStories, setFormStoryOpen) => {

  const [sending, setSending] = useState(false);
  const [title, setTitle] = useState(story ? story.title : "");
  const [subtitle, setSubtitle] = useState(story ? story.subtitle : "");
  const [briefDescription, setBriefDescription] = useState(story ? story.briefDescription : "");
  const [description, setDescription] = useState(story ? story.description : "");

  const [photo, setPhoto] = useState([]);

  useEffect(() => {
    setPhoto([]);
  }, []);

  useEffect(() => {
  }, [sending]);

  const handleFormSubmit = () => {

    const formattedDate = getDate();
    const photoName = title;

    let newStory = {
      title: title,
      subtitle: subtitle,
      briefDescription: briefDescription,
      description: description,
      photo: photo,
      photoName: photoName,
      date: formattedDate,
    };
    sendStory(newStory);
  }

  const sendStory = async (newStory) => {

    setSending(true);
    try {
      if (type === 'create') {
        let response = await createStory(newStory);
        if (response.status === 201) {
          loadStories();
          setFormStoryOpen(false);
          setSending(false);
        } else {
          setSending(false);
        }
      }
      if (type === 'modify') {
        let response = await updateStory(story.id, newStory);
        if (response.status === 200) {
          loadStories();
          setFormStoryOpen(false);
          setSending(false);
        } else {
          setSending(false);
        }
      }
    } catch (error) {
      setSending(false);
    }
  }

  const onInputChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "subtitle":
        setSubtitle(value);
        break;
      case "briefDescription":
        setBriefDescription(value);
        break;
      case "description":
        setDescription(value);
        break;
      default:
        break;
    }
  };

  return {
    sending,
    handleFormSubmit,
    onInputChange,
    setPhoto,
    title,
    subtitle,
    briefDescription,
    description
  }
}

export default useHandleForm

