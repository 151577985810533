import React, { useEffect, useState } from 'react';
import '../../../styles/general.css';
import '../../../styles/header.css';
import HamburgerHeader from './HamburgerHeader';
import HeaderLogo from './HeaderLogo';
import HeaderMenu from './HeaderMenu';

function Header(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuStateChange = (isOpen) => {
    setIsMenuOpen(isOpen);
  };


  return (
    <div className={`Header ${isMenuOpen ? 'hamburgerMenuOpen' : ''} 
    ${props.clipPath ? 'clip-path' : ''}`  // Add the rounded border only if the clipPath property is set to true.
    }>                                     
      <HamburgerHeader
        viewId={props.viewId}
        onStateChange={handleMenuStateChange}
      />
      <div className="HeaderCont">
        <div className='HeaderLogoContainer'>
          <HeaderLogo />
        </div>
        <div className="HeaderTitle">Cooperadora de los hospitales  <br></br> de Mar del Plata</div>
        <div className='MenuLargeScreen'>
          <HeaderMenu viewId={props.viewId} />
        </div>
        
        
      </div>
    </div>
  );
}

export default Header;